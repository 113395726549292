import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import Button from 'components/Button';
import Modal, { ModalProps } from 'components/Modal';
import { Input } from 'components';
import { useForm, Controller } from 'react-hook-form';

interface ModalTaskNotesProps extends ModalProps {
  onSubmit: () => Promise<[]>;
  notes: any;
}

const ModalTaskNotes: React.FC<ModalTaskNotesProps> = ({
  onSubmit,
  onClose,
  notes,
  open,
  className,
  children,
  ...props
}) => {

  const { control, handleSubmit } = useForm();

  return (
    <StyledModalTaskNotes
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      {...props}>
      {children}
      {notes && notes.length>0 &&
        <ul className="taskNotes">
        {notes.map((row) =>
          <li><p>{row.note}</p><span>By {row.name} on {dayjs(1000*row.created_on).format('MMM DD, YYYY, HH:mm A')}</span></li>
        )}
      </ul>
      }
      {!notes || notes.length == 0 &&
        <p>No notes</p>
      }
      
      <p className="notecreateheader"><b>Create new note</b></p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="note"
          rules={{ required: 'Required' }}
          render={({ field, formState: { errors } }) => (
            <Input
              as="textarea"
              label="New note"
              className="notearea"
              placeholder="New note"
              error={errors.note?.message}
              data-cy="input_description"
              {...field}
            />
          )}
        />
        <Button type="submit" large>Create new note</Button>
      </form>
    </StyledModalTaskNotes>
  );
};

export default ModalTaskNotes;

const StyledModalTaskNotes = styled(Modal)`
  .notearea{width:100%;}
  .notecreateheader{margin:0;}
  .taskNotes {
    list-style:none;padding: 0;
  }
  .taskNotes p {
    margin:0;line-height:normal;
  }
  .taskNotes li{
    padding: 5px;
    background: #f3f3f3;margin-bottom: 10px;
  }
  .taskNotes span{font-size: 10px;color: grey;}
  .modal-content {
    min-width: 300px;
    max-width: 300px;
    font-size: 16px;
    line-height: 24px;
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 14px;
    }
  }

  button.large, .dropdown-wide {
    width: 100%;
    margin-top: 16px;
  }

  .dropdown-wide > label, .attorney__input > label {
    font-size: 12px;
  }

  .sale-side__checkbox {
    margin-bottom: 16px;
  }
`;
